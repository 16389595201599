<template>
  <v-container :class="{ 'pr-0 pl-0 pt-0': !$vuetify.breakpoint.mdAndUp }">
    <v-row>
      <v-col cols="12" class="py-1">
        <h3 class="text-h5 ml-1 font-weight-medium">
          Modificación de orden
        </h3>
      </v-col>
      <v-col cols="12">
        <v-card>
          <v-slide-x-transition leave-absolute mode="in-out">
            <v-container class="px-4 mb-2" v-if="!loader">
              <v-row>
                <v-col cols="12" class="text-center">
                  <p class="text-h6 ma-0">
                    <span class="text--secondary text-subtitle-1">Orden </span>
                    {{ orderID }}
                  </p>
                  <p class="caption ma-0">
                    Creado el {{ product.created | toDateTransform }}
                  </p>
                  <v-chip class="ma-0" small>
                    Vendido por
                    <span class="font-weight-bold ml-1">{{
                      product.sold_by.name
                    }}</span>
                  </v-chip>
                </v-col>
              </v-row>

              <p class="caption ma-0 mt-2">
                Lista de productos
                <span class="font-weight-bold"
                  >({{ product.product_list.length }})</span
                >
              </p>
              <template v-for="(productList, index) in product.product_list">
                <v-card
                  :key="productList.id"
                  class="no-select my-1"
                  :class="{
                    'red lighten-5': productList.local_status_delete,
                    'yellow lighten-5':
                      productList.local_quantity != productList.total_products
                  }"
                  outlined
                >
                  <v-list-item>
                    <v-list-item-content class="py-1">
                      <v-list-item-title
                        class="text-subtitle-1"
                        :class="{
                          'text-decoration-line-through':
                            productList.local_status_delete
                        }"
                      >
                        <p class="ma-0 caption text-right">
                          SKU: {{ productList.id }}
                        </p>
                        <p class="ma-0">{{ productList.name_product }}</p>
                        <p class="ma-0 text-body-2">
                          {{ productList.description }}
                        </p>
                      </v-list-item-title>
                      <v-list-item-title
                        class="ma-0 mb-1"
                        :class="{
                          'text-decoration-line-through':
                            productList.local_status_delete
                        }"
                      >
                        <v-divider class="my-1"></v-divider>
                        <div class="d-flex justify-space-between mb-1">
                          <p class="ma-0 text-body-2">
                            Precio por pieza
                          </p>
                          <p class="ma-0 text-body-2">
                            <span
                              >$
                              {{
                                productList.local_wholesale.wholesale_price
                              }}</span
                            >
                            <span
                              class="text-decoration-line-through ml-2"
                              v-if="
                                productList.local_price_by_unit >
                                  productList.local_wholesale.wholesale_price
                              "
                              >$ {{ productList.local_price_by_unit }}</span
                            >
                          </p>
                        </div>
                        <v-divider class="my-1"></v-divider>
                      </v-list-item-title>
                      <v-list-item-title
                        v-if="productList.extra_data.wholesale"
                        class="text-body-2 ma-0"
                        :class="{
                          'text-decoration-line-through':
                            productList.local_status_delete
                        }"
                      >
                        <div class="d-flex justify-space-between mb-1">
                          <p class="ma-0 caption text--disabled">
                            Precio por mayoreo
                          </p>
                          <p class="ma-0 caption text--disabled">
                            Min. Mayoreo
                          </p>
                        </div>
                        <template
                          v-for="(wholesale, index) in productList.extra_data
                            .wholesale"
                        >
                          <div
                            :key="index + '-wholesale-detail'"
                            class="d-flex justify-space-between mb-1"
                          >
                            <p
                              class="ma-0"
                              v-html="
                                wholesaleDiscount(
                                  wholesale.wholesale_price,
                                  productList.price_by_unit
                                )
                              "
                            ></p>
                            <p class="ma-0">
                              {{
                                wholesale.minimum_products | nameProductParts
                              }}
                            </p>
                          </div>
                        </template>
                        <v-divider class="my-1"></v-divider>
                      </v-list-item-title>
                      <v-list-item-title
                        class="d-flex align-center justify-space-between text-decoration-none"
                      >
                        <div style="width: 150px">
                          <v-text-field
                            type="number"
                            :disabled="productList.local_status_delete"
                            @input="updateQuantityData(productList, index)"
                            dense
                            label="Piezas"
                            v-model="productList.local_quantity"
                            outlined
                            class="mt-2"
                            hide-details
                          ></v-text-field>
                        </div>
                        <v-btn
                          v-if="
                            !productList.local_status_delete &&
                              product.product_list.length > 1
                          "
                          icon
                          color="error"
                          @click="deleteProduct(index)"
                        >
                          <v-icon>delete</v-icon>
                        </v-btn>
                        <v-btn
                          v-if="productList.local_status_delete"
                          icon
                          @click="restoreProduct(index)"
                        >
                          <v-icon>restore</v-icon>
                        </v-btn>
                      </v-list-item-title>
                      <v-list-item-title
                        class="text-body-2 ma-0"
                        :class="{
                          'text-decoration-line-through':
                            productList.local_status_delete
                        }"
                      >
                        <p class="ma-0 text-body-1 text-right">
                          <span class="mr-2 text-h6">
                            Total $
                            {{ productList.local_total_sold }}
                          </span>
                          <span
                            v-if="
                              productList.local_subtotal_price >
                                productList.local_total_sold
                            "
                            class="text-subtitle-1 text-decoration-line-through"
                          >
                            ${{ productList.local_subtotal_price }}
                          </span>
                        </p>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
              </template>
              <v-row>
                <v-col cols="12" class="pt-2 pb-0">
                  <p class="text-h6 mb-0 text-center">Resumen</p>
                  <v-row>
                    <v-col
                      cols="12"
                      class="d-flex justify-space-between pt-1 pb-1"
                    >
                      <div class="text-subtitle-2">Piezas Totales</div>
                      <div class="text-subtitle-2 text--secondary">
                        {{ totalProducts | nameProductParts }}
                      </div>
                    </v-col>
                    <v-col
                      cols="12"
                      class="d-flex justify-space-between pt-1 pb-1"
                    >
                      <div class="text-subtitle-2">Subtotal</div>
                      <div class="text-subtitle-2 text--secondary">
                        $ {{ subtotalSold }}
                      </div>
                    </v-col>
                    <v-col
                      cols="12"
                      class="d-flex justify-space-between pt-1 pb-1"
                    >
                      <div class="text-subtitle-2">Total (inicial)</div>
                      <div class="text-subtitle-2 text--secondary">
                        $ {{ product.total_sold }}
                      </div>
                    </v-col>
                    <v-col
                      cols="12"
                      class="d-flex justify-space-between pt-1 pb-1"
                    >
                      <div class="text-subtitle-2">
                        Total (modificado)
                      </div>
                      <div class="text-subtitle-2 text--secondary">
                        $ {{ totalSold }}
                      </div>
                    </v-col>
                    <v-col
                      cols="12"
                      class="d-flex justify-space-between pt-1 pb-1"
                    >
                      <div class="text-subtitle-2 font-weight-bold">
                        Diferencia del Total
                      </div>
                      <div
                        class="text-subtitle-2 font-weight-bold text--secondary"
                      >
                        {{ totalSoldDifference(product.total_sold) }}
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12">
                  <v-btn color="error" block @click="dialogDelete = true"
                    >Eliminar orden</v-btn
                  >
                </v-col>
              </v-row>
            </v-container>
          </v-slide-x-transition>
          <v-container v-if="loader">
            <v-skeleton-loader
              type="list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, actions"
            ></v-skeleton-loader>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="dialogDelete" width="500">
      <v-card>
        <v-card-title class="headline grey lighten-2">
          <span class="text-subtitle-2">Eliminar orden</span> {{ orderID }}
        </v-card-title>

        <v-card-text class="mt-4">
          ¿Estas seguro de eliminar la orden?
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogDelete = false">
            Cancelar
          </v-btn>
          <v-btn color="error" @click="deleteOrder">
            Eliminar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-scroll-y-reverse-transition leave-absolute>
      <app-save-changes
        v-if="wereChangesDetected"
        :saveChanges="saveChanges"
        :discardChanges="discardChanges"
      ></app-save-changes>
    </v-scroll-y-reverse-transition>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { database } from "@/config/firebase";
import { showMessage, errorMessage } from "@/services/MessageService";
import moment from "moment";
import SaveChanges from "@/components/app/SaveChanges";
import {
  loaderDone,
  loaderStart,
  showMessageLoader
} from "@/services/LoaderService";

export default {
  data() {
    return {
      product: null,
      originalProduct: null,
      loader: true,
      edit_order: false,
      wereChangesDetected: false,
      dialogDelete: false
    };
  },
  props: {
    orderID: {
      required: true
    }
  },
  computed: {
    ...mapState("user", ["data", "userInfoData"]),
    ...mapState("business", ["currentBranchID"]),
    totalProducts() {
      let totalProductsCounter = 0;
      this.product.product_list.forEach(product => {
        if (product.local_quantity && !product.local_status_delete)
          totalProductsCounter += parseInt(product.local_quantity);
      });
      return totalProductsCounter;
    },
    totalSold() {
      let totalSoldCounter = 0;
      this.product.product_list.forEach(product => {
        if (product.local_total_sold && !product.local_status_delete)
          totalSoldCounter += parseInt(product.local_total_sold);
      });
      return totalSoldCounter;
    },
    totalSoldCost() {
      let totalSoldCounter = 0;
      this.product.product_list.forEach(product => {
        if (product.cost_price && !product.local_status_delete)
          totalSoldCounter += parseInt(
            product.cost_price * product.local_quantity
          );
      });
      return totalSoldCounter;
    },
    subtotalSold() {
      let totalSoldCounter = 0;
      this.product.product_list.forEach(product => {
        if (product.local_subtotal_price && !product.local_status_delete)
          totalSoldCounter += parseInt(product.local_subtotal_price);
      });
      return totalSoldCounter;
    }
  },
  mounted() {
    this.getOrderDetail();
  },
  methods: {
    ...mapActions("products", ["updateAnalyticsInventory"]),
    async getOrderDetail() {
      this.loader = true;
      try {
        await database(`sales/${this.orderID}`).once(
          "value",
          async snapshot => {
            if (snapshot.exists()) {
              const data = snapshot.val();
              this.originalProduct = snapshot.val();
              for (let i = 0; i < data.product_list.length; i++) {
                const product = data.product_list[i];
                data.product_list[i].local_quantity = product.total_products;
                data.product_list[i].local_status_edit = false;
                data.product_list[i].local_status_delete = false;
                const extraProductData = await this.getProductData(product.id);
                if (extraProductData)
                  data.product_list[i].extra_data = extraProductData;
                data.product_list[i].local_price_by_unit =
                  product.price_by_unit;
                if (product.wholesale)
                  data.product_list[i].local_wholesale = {
                    minimum_products: product.wholesale.minimum_products,
                    wholesale_price: product.wholesale.wholesale_price
                  };
                else
                  data.product_list[i].local_wholesale = {
                    minimum_products: null,
                    wholesale_price: product.price_by_unit
                  };
                data.product_list[i].local_total_sold = product.total_price;
                data.product_list[i].local_subtotal_price =
                  product.subtotal_price;
              }
              this.product = data;
              this.loader = false;
            } else {
              showMessage({
                message:
                  "La orden que estas buscando no fue encontrada, verifica el ID e intenta de nuevo",
                timeout: 3000,
                color: "warning"
              });
              this.$router.push({ name: "orders" });
            }
          }
        );
      } catch (error) {
        errorMessage({
          message: "Error al obtener el detalle de la orden, código:" + error
        });
      }
    },
    deleteProduct(index) {
      this.wereChangesDetected = true;
      this.product.product_list[index].local_status_delete = true;
      this.product.product_list[
        index
      ].local_quantity = this.product.product_list[index].total_products;
    },
    async getProductData(id) {
      try {
        const productRef = database(`store/${id}`);
        const productData = await productRef.once("value");
        if (productData.exists()) {
          const data = productData.val();
          const extraData = {};
          if (data.branch_office[this.currentBranchID]) {
            if (data.branch_office[this.currentBranchID].inventory) {
              extraData.inventory = {
                min_inventory:
                  data.branch_office[this.currentBranchID].inventory
                    .min_inventory,
                total_inventory:
                  data.branch_office[this.currentBranchID].inventory
                    .total_inventory
              };
              extraData.cost_price = data.cost_price;
            }
          }
          if (data.wholesale) {
            extraData.wholesale = data.wholesale;
          }
          return extraData;
        }
      } catch (error) {
        errorMessage({
          message:
            "Error al obtener el detalle de los productos, código:" + error
        });
      }
    },
    restoreProduct(index) {
      this.product.product_list[index].local_status_delete = false;
      this.product.product_list[
        index
      ].local_quantity = this.product.product_list[index].total_products;
    },
    priceWithWholesale(price, wholesalePrice) {
      if (price > wholesalePrice) {
        return `$ ${wholesalePrice} <span class="text-subtitle-2 text--secondary text-decoration-line-through ml-1">$ ${price}</span>`;
      }
      return `$ ${price}`;
    },
    updateQuantityData(product, index) {
      this.wereChangesDetected = true;
      const products = product.local_quantity;
      const price = product.price_by_unit;
      const wholesales = product.extra_data.wholesale;
      const total = parseFloat(products) * parseFloat(price);
      let totalPriceUnit = parseFloat(price);
      let minProductsWholesale = null;
      if (product.local_quantity != product.total_products)
        this.product.product_list[index].local_status_edit = true;
      else this.product.product_list[index].local_status_edit = false;

      this.product.product_list[index].local_total_sold = total;
      this.product.product_list[index].local_subtotal_price = total;
      this.product.product_list[index].local_price_by_unit = price;
      this.product.product_list[
        index
      ].local_wholesale.wholesale_price = totalPriceUnit;
      this.product.product_list[
        index
      ].local_wholesale.minimum_products = minProductsWholesale;
      if (wholesales) {
        for (let i = 0; i < wholesales.length; i++) {
          const wholesale = wholesales[i];
          if (products >= wholesale.minimum_products) {
            totalPriceUnit = parseFloat(wholesale.wholesale_price);
            minProductsWholesale = parseInt(wholesale.minimum_products);
          }
        }
        const totalWholesale = parseFloat(totalPriceUnit * products);
        this.product.product_list[index].local_total_sold = totalWholesale;
        this.product.product_list[index].local_subtotal_price = total;
        this.product.product_list[index].local_price_by_unit = price;
        this.product.product_list[
          index
        ].local_wholesale.wholesale_price = totalPriceUnit;
        this.product.product_list[
          index
        ].local_wholesale.minimum_products = minProductsWholesale;
      }
    },
    async saveChanges() {
      loaderStart();
      let originalProduct = JSON.parse(JSON.stringify(this.originalProduct));
      let productInventoryList = [];
      let isModified = false;
      const month = moment.unix(this.originalProduct.created).format("MM");
      const year = moment.unix(this.originalProduct.created).format("YYYY");
      const monthSale = `${parseInt(month)}${year}`;
      const createdTransform = moment.unix(this.originalProduct.created);
      const isToday = createdTransform.isSame(moment(), "day");
      try {
        const businessRef = `branch_offices/${this.currentBranchID}`;
        for (let i = 0; i < this.product.product_list.length; i++) {
          const product = this.product.product_list[i];
          if (product.local_status_edit || product.local_status_delete) {
            isModified = true;
            if (product.local_status_delete) {
              const indexDelete = originalProduct.product_list.findIndex(
                el => el.id === product.id
              );
              if (product.extra_data.inventory) {
                productInventoryList.push({
                  product_id: product.id,
                  total_money_cost:
                    product.extra_data.cost_price *
                    originalProduct.product_list[indexDelete].total_products,
                  total_money:
                    product.price_by_unit *
                    originalProduct.product_list[indexDelete].total_products,
                  total: parseInt(
                    originalProduct.product_list[indexDelete].total_products
                  ),
                  delete: true
                });
              }
              originalProduct.product_list.splice(indexDelete, 1);
            } else {
              const indexEdit = originalProduct.product_list.findIndex(
                el => el.id === product.id
              );
              if (product.extra_data.inventory) {
                productInventoryList.push({
                  product_id: product.id,
                  total_money: this.calculateTotalMByProduct(
                    product.price_by_unit,
                    product.local_quantity,
                    originalProduct.product_list[indexEdit].total_products
                  ),
                  total_money_cost: this.calculateTotalMByProduct(
                    product.extra_data.cost_price,
                    product.local_quantity,
                    originalProduct.product_list[indexEdit].total_products
                  ),
                  total:
                    parseInt(product.local_quantity) -
                    parseInt(
                      originalProduct.product_list[indexEdit].total_products
                    ),
                  edit: true
                });
              }
              originalProduct.product_list[indexEdit].total_price = parseFloat(
                product.local_total_sold
              );
              originalProduct.product_list[indexEdit].total_products = parseInt(
                product.local_quantity
              );
              originalProduct.product_list[
                indexEdit
              ].subtotal_price = parseFloat(product.local_subtotal_price);
              if (
                parseInt(product.local_subtotal_price) >
                parseInt(product.local_total_sold)
              )
                originalProduct.product_list[indexEdit].discount = true;
              else originalProduct.product_list[indexEdit].discount = false;
              if (product.local_wholesale.minimum_products) {
                originalProduct.product_list[indexEdit].wholesale = {
                  ...product.local_wholesale
                };
              } else {
                if (originalProduct.product_list[indexEdit].wholesale)
                  delete originalProduct.product_list[indexEdit].wholesale;
              }
            }
          }
        }
        let total_inventory_data = 0;
        let total_money_data = 0;
        let total_money_data_cost = 0;
        if (isModified) {
          if (originalProduct.product_list.length === 0) {
            loaderDone();
            this.dialogDelete = true;
            return;
          } else {
            showMessageLoader("Actualizando la orden");
            originalProduct.total_sold = parseFloat(this.totalSold);
            originalProduct.total_cost_price = parseFloat(this.totalSoldCost);
            originalProduct.total_products = parseInt(this.totalProducts);
            originalProduct.subtotal_price = parseFloat(this.subtotalSold);
            originalProduct.discount =
              parseInt(this.subtotalSold) - parseInt(this.totalSold);
            originalProduct.edited = true;
            const varianceOrder =
              parseFloat(this.totalSold) -
              parseFloat(this.originalProduct.total_sold);
            originalProduct.edited_metadata = {
              last_update: moment().unix(),
              updated_by: this.data.displayName,
              last_total: parseFloat(this.originalProduct.total_sold),
              last_subtotal_price: parseFloat(
                this.originalProduct.subtotal_price
              ),
              last_total_products: parseInt(
                this.originalProduct.total_products
              ),
              variance: varianceOrder
            };
            const analyticsData = {
              created: this.originalProduct.created,
              id: this.originalProduct.id,
              payment_method: this.originalProduct.payment_method,
              total_products: parseInt(this.totalProducts),
              total_sold: parseFloat(this.totalSold)
            };
            analyticsData.edited = true;

            // :: Update analytics by month
            await database(
              `${businessRef}/analytics/sales/${monthSale}/sales_list/${this.originalProduct.id}`
            ).update(analyticsData);
            // :: Update analytics statistics transaction
            await database(
              `${businessRef}/analytics/sales/${monthSale}/total_sold`
            ).transaction(actualTotalSold => {
              actualTotalSold = actualTotalSold || 0;
              actualTotalSold += varianceOrder;
              return actualTotalSold;
            });
            if (isToday) {
              // :: Update analytics today
              await database(
                `${businessRef}/analytics/sales/today/sales_list/${this.originalProduct.id}`
              ).update(analyticsData);
              // :: Update analytics statistics transaction
              await database(
                `${businessRef}/analytics/sales/today/total_sold`
              ).transaction(actualTotalSold => {
                actualTotalSold = actualTotalSold || 0;
                actualTotalSold += varianceOrder;
                return actualTotalSold;
              });
            }
            analyticsData.edited_metadata = {
              last_update: moment().unix(),
              updated_by: this.data.displayName,
              last_total: parseFloat(this.originalProduct.total_sold),
              last_total_products: parseInt(
                this.originalProduct.total_products
              ),
              variance: varianceOrder
            };
            // :: Update Global records Sale
            await database(`sales/${this.originalProduct.id}`).update(
              originalProduct
            );

            if (productInventoryList.length > 0) {
              // :: Update inventory
              showMessageLoader("Actualizando el inventario correspondiente");
              for (
                let iProduct = 0;
                iProduct < productInventoryList.length;
                iProduct++
              ) {
                const product = productInventoryList[iProduct];
                if (product.delete) {
                  total_inventory_data += product.total;
                  total_money_data += product.total_money;
                  total_money_data_cost += product.total_money_cost;
                } else {
                  total_inventory_data -= product.total;
                  total_money_data -= product.total_money;
                  total_money_data_cost -= product.total_money_cost;
                }
                database(
                  `store/${product.product_id}/branch_office/${this.currentBranchID}/inventory/total_inventory`
                ).transaction(actualTotalInventory => {
                  actualTotalInventory = actualTotalInventory || 0;
                  if (product.delete) actualTotalInventory += product.total;
                  if (product.edit) actualTotalInventory -= product.total;
                  return actualTotalInventory;
                });
              }
              showMessageLoader("Actualizando estadisticas globales");
              // :: Update local Store statistics with transaction
              this.updateAnalyticsInventory({
                totalInventory: total_inventory_data,
                totalMoney: total_money_data_cost,
                totalMoneyPrice: total_money_data,
                currentBranch: this.currentBranchID
              });
            }
            this.$router.go(-1);
            showMessage({
              message: "Se actualizo la orden correctamente",
              timeout: 2000,
              color: "success"
            });
            loaderDone();
          }
        } else {
          this.wereChangesDetected = false;
          loaderDone();
        }
      } catch (error) {
        errorMessage({
          message: "Error al actualizar la orden, code:" + error
        });
        loaderDone();
      }
    },
    async deleteOrder() {
      this.dialogDelete = false;
      loaderStart();
      const month = moment.unix(this.originalProduct.created).format("MM");
      const year = moment.unix(this.originalProduct.created).format("YYYY");
      const monthSale = `${parseInt(month)}${year}`;
      const createdTransform = moment.unix(this.originalProduct.created);
      const isToday = createdTransform.isSame(moment(), "day");
      try {
        const businessRef = `branch_offices/${this.currentBranchID}`;
        showMessageLoader("Eliminando la orden");
        // :: Delete single record
        await database(`sales/${this.originalProduct.id}`).remove();
        // :: Update analytics
        await database(
          `${businessRef}/analytics/sales/${monthSale}/sales_list/${this.originalProduct.id}`
        ).remove();
        // :: Update analytics statistics transaction
        database(
          `${businessRef}/analytics/sales/${monthSale}/total_sold`
        ).transaction(actualTotalSold => {
          actualTotalSold = actualTotalSold || 0;
          actualTotalSold -= this.originalProduct.total_sold;
          return actualTotalSold;
        });
        database(
          `${businessRef}/analytics/sales/${monthSale}/total_orders`
        ).transaction(actualTotalOrders => {
          actualTotalOrders = actualTotalOrders || 0;
          actualTotalOrders--;
          return actualTotalOrders;
        });
        if (isToday) {
          // :: Update analytics
          await database(
            `${businessRef}/analytics/sales/today/sales_list/${this.originalProduct.id}`
          ).remove();
          // :: Update analytics statistics transaction
          database(
            `${businessRef}/analytics/sales/today/total_sold`
          ).transaction(actualTotalSold => {
            actualTotalSold = actualTotalSold || 0;
            actualTotalSold -= this.originalProduct.total_sold;
            return actualTotalSold;
          });
          database(
            `${businessRef}/analytics/sales/today/total_orders`
          ).transaction(actualTotalOrders => {
            actualTotalOrders = actualTotalOrders || 0;
            actualTotalOrders--;
            return actualTotalOrders;
          });
        }
        let total_inventory_data = 0;
        let total_money_data = 0;
        let total_money_data_cost = 0;
        showMessageLoader("Actualizando el inventario correspondiente");
        for (
          let iProduct = 0;
          iProduct < this.product.product_list.length;
          iProduct++
        ) {
          const product = this.product.product_list[iProduct];
          if (product.extra_data.inventory) {
            const indexDelete = this.originalProduct.product_list.findIndex(
              el => el.id === product.id
            );
            const totalInventoryAnalytics = this.originalProduct.product_list[
              indexDelete
            ].total_products;
            const totalMoneyAnalytics =
              product.price_by_unit * totalInventoryAnalytics;
            const totalMoneyCostAnalytics =
              product.extra_data.cost_price * totalInventoryAnalytics;
            total_inventory_data += totalInventoryAnalytics;
            total_money_data +=
              Math.round((totalMoneyAnalytics + Number.EPSILON) * 100) / 100;
            total_money_data_cost +=
              Math.round((totalMoneyCostAnalytics + Number.EPSILON) * 100) /
              100;
            database(
              `store/${product.id}/branch_office/${this.currentBranchID}/inventory/total_inventory`
            ).transaction(actualTotalInventory => {
              actualTotalInventory = actualTotalInventory || 0;
              actualTotalInventory += this.originalProduct.product_list[
                indexDelete
              ].total_products;
              return actualTotalInventory;
            });
          }
        }
        showMessageLoader("Actualizando estadisticas globales");
        // :: Update local & Global Store Inventory statistics
        this.updateAnalyticsInventory({
          totalInventory: total_inventory_data,
          totalMoney: total_money_data_cost,
          totalMoneyPrice: total_money_data,
          currentBranch: this.currentBranchID
        });
        this.wereChangesDetected = false;
        loaderDone();
        showMessage({
          message: "La orden fue eliminada exitosamente",
          timeout: 2000,
          color: "success"
        });
        this.$router.push({ name: "orders" });
      } catch (error) {
        errorMessage({
          message: "Error al eliminar la orden, code:" + error
        });
        loaderDone();
      }
    },
    transactionStoreInventory(
      databaseRef,
      total_inventory_data,
      total_money_data
    ) {
      database(`${databaseRef}/total_products`).transaction(
        actualTotalProducts => {
          actualTotalProducts = actualTotalProducts || 0;
          actualTotalProducts += total_inventory_data;
          return actualTotalProducts;
        }
      );
      database(`${databaseRef}/total_money`).transaction(actualTotalMoney => {
        actualTotalMoney = actualTotalMoney || 0;
        actualTotalMoney += total_money_data;
        return Math.round((actualTotalMoney + Number.EPSILON) * 100) / 100;
      });
    },
    discardChanges() {
      this.wereChangesDetected = false;
      this.$router.go(-1);
    },
    wholesaleDiscount(wholesale_price, price) {
      const totalPrice = parseFloat(price);
      const wholesalePriceByUnit = parseFloat(wholesale_price);
      const wholesalePrice = wholesalePriceByUnit * 100;
      const discount = 100 - wholesalePrice / totalPrice;
      return `$ ${wholesalePriceByUnit} <span class="caption text--secondary ml-2"> ${parseFloat(
        discount
      ).toFixed(1)}% Descuento </span> `;
    },
    totalSoldDifference(totalSold) {
      let totalSoldCounter = 0;
      this.product.product_list.forEach(product => {
        if (product.local_total_sold && !product.local_status_delete)
          totalSoldCounter += parseInt(product.local_total_sold);
      });
      const total = totalSoldCounter - parseInt(totalSold);
      if (total < 0) return `- $ ${Math.abs(total)}`;
      else return `$ ${total}`;
    },
    calculateTotalMByProduct(cost, local_quantity, original_quantity) {
      const totalCost = parseFloat(cost);
      const total = parseInt(local_quantity) - parseInt(original_quantity);
      const total_money = total * totalCost;
      return Math.round((total_money + Number.EPSILON) * 100) / 100;
    }
  },
  components: {
    AppSaveChanges: SaveChanges
  },
  filters: {
    nameProductParts(parts) {
      if (parts == 1) return parts + " Pieza";
      else return parts + " Piezas";
    },
    toDateTransform(timeUnix) {
      if (timeUnix)
        return moment.unix(timeUnix).format("dddd DD [a las] h:mm [de] YYYY");
      return "No hay ventas recientes";
    }
  }
};
</script>
